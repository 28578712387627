import { CartItem, CustomerType } from '@/types'

export type State = {
  cartItems: CartItem[] | []
  total: number
  customer: CustomerType | null
}

export const state: State = {
  cartItems: [],
  total: 0,
  customer: null,
}
