
import { ShoppingCartIcon } from '@heroicons/vue/outline'
import { computed, defineComponent } from 'vue'

import { navigation } from '@/core/navigation'
import TheNavigationMobile from '@/layout/navigation/TheNavigationMobile.vue'
import { store } from '@/store'
import { CartItem } from '@/types'

export default defineComponent({
  name: 'TheNavigationDesktop',
  components: { ShoppingCartIcon, TheNavigationMobile },
  setup() {
    const cartItems = computed(() => {
      let count = 0
      store.getters.getCartItems.map((item: CartItem) => (count += item.quantity))
      return count
    })
    return {
      navigation,
      cartItems,
    }
  },
})
