
import { useQuery } from '@vue/apollo-composable'
import { computed, defineComponent } from 'vue'

import TheNavigationDesktop from '@/layout/navigation/TheNavigationDesktop.vue'
import { getPageQuery } from '@/schema/getPage'
import { store } from '@/store'
import { PageType } from '@/types'

export default defineComponent({
  name: 'TheNavigation',
  components: { TheNavigationDesktop },
  setup() {
    const isStoreOpen = computed(() => store.state.storeConfig.isOpen)
    // Any Alert from WP
    const { result, loading } = useQuery<PageType>(getPageQuery, {
      id: 'cG9zdDo0MTQ=',
    })
    const resultValue = computed(() => {
      return result.value
    })
    // Store close content from WP
    const { result: storeCloseContent, loading: storeCloseContentLoading } = useQuery<PageType>(getPageQuery, {
      id: 'cG9zdDo0MzU=',
    })
    const storeCloseResultValue = computed(() => {
      return storeCloseContent.value
    })
    return {
      isStoreOpen,
      resultValue,
      loading,
      storeCloseResultValue,
      storeCloseContentLoading,
    }
  },
})
