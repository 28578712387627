import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import { store } from '@/store'
import { ActionTypes } from '@/store/modules/storeConfig/action-types'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('../views/Menu.vue'),
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/Checkout.vue'),
  },
  {
    path: '/thank-you/:id',
    name: 'Thank You',
    component: () => import('../views/Thankyou.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('../views/Privacy.vue'),
  },
  // { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../views/NotFound.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }
  },
})

// Check Store is open when page change
router.beforeEach(async (to, from, next) => {
  await store.dispatch(ActionTypes.SET_STORE_OPEN)
  next()
})

export default router
