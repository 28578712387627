import { CommitOptions, DispatchOptions, Module, Store as VuexStore } from 'vuex'

import { RootState } from '@/store'
import { Actions, actions } from '@/store/modules/storeConfig/actions'
import { Getters, getters } from '@/store/modules/storeConfig/getters'
import { Mutations, mutations } from '@/store/modules/storeConfig/mutations'
import type { State } from '@/store/modules/storeConfig/state'
import { state } from '@/store/modules/storeConfig/state'

export type StoreConfigStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(key: K, payload: P, options?: CommitOptions): ReturnType<Mutations[K]>
} & {
  dispatch<K extends keyof Actions>(key: K, payload?: Parameters<Actions[K]>[1], options?: DispatchOptions): ReturnType<Actions[K]>
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>
  }
}
export { State }
export const store: Module<State, RootState> = {
  state,
  getters,
  actions,
  mutations,
}
