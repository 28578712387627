
import dayjs from 'dayjs'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheFooter',
  setup() {
    const currentYear = dayjs().format('YYYY')
    return {
      currentYear,
    }
  },
})
