import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0ab79b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navbar max-w-7xl mx-auto min-h-16" }
const _hoisted_2 = { class: "navbar-start" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "navbar-center hidden sm:block" }
const _hoisted_5 = { class: "menu horizontal items-stretch space-x-5" }
const _hoisted_6 = { class: "navbar-center block sm:block" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "navbar-end" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = { class: "cart-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_the_navigation_mobile = _resolveComponent("the-navigation-mobile")!
  const _component_ShoppingCartIcon = _resolveComponent("ShoppingCartIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/",
        class: "hidden sm:block"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/logo-hn.png'),
            alt: "Hanoi Eatery Logo",
            class: "w-auto h-10"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      _createVNode(_component_the_navigation_mobile, { class: "block sm:hidden" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: item.name,
            to: item.path,
            "active-class": "!btn-primary",
            class: "btn btn-ghost"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/logo-hn.png'),
            alt: "Hanoi Eatery Logo",
            class: "w-auto h-10 block sm:hidden"
          }, null, 8, _hoisted_7)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_router_link, { to: "/checkout" }, {
          default: _withCtx(() => [
            _createVNode(_component_ShoppingCartIcon, { class: "cart-icon" }),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.cartItems), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}