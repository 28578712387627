import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_menu_icon = _resolveComponent("menu-icon")!
  const _component_x_icon = _resolveComponent("x-icon")!
  const _component_menu_button = _resolveComponent("menu-button")!
  const _component_menu_item = _resolveComponent("menu-item")!
  const _component_menu_items = _resolveComponent("menu-items")!
  const _component_headless_menu = _resolveComponent("headless-menu")!

  return (_openBlock(), _createBlock(_component_headless_menu, {
    as: "div",
    class: "relative"
  }, {
    default: _withCtx(({ open }) => [
      _createVNode(_component_menu_button, { class: "inline-flex items-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_menu_icon, {
            class: _normalizeClass(["nav-mb-icon", open && 'hidden'])
          }, null, 8, ["class"]),
          _createVNode(_component_x_icon, {
            class: _normalizeClass(["nav-mb-icon swap-on", !open && 'hidden'])
          }, null, 8, ["class"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_menu_items, { class: "menu-items" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
            return (_openBlock(), _createBlock(_component_menu_item, {
              key: item.name,
              as: "a",
              href: item.path
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(['btn w-full', _ctx.currentRoute === item.path ? 'btn-primary' : 'btn-ghost'])
                }, _toDisplayString(item.name), 3)
              ]),
              _: 2
            }, 1032, ["href"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}