import gql from 'graphql-tag'

export const getPageQuery = gql`
  query getPage($id: ID!) {
    page(id: $id) {
      id
      content
      title
    }
  }
`
