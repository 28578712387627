
import { Menu as HeadlessMenu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/solid'
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import { navigation } from '@/core/navigation'

export default defineComponent({
  name: 'TheNavigationMobile',
  components: { HeadlessMenu, MenuButton, MenuItem, MenuItems, MenuIcon, XIcon },
  setup() {
    const route = useRouter()
    const currentRoute = computed(() => route.currentRoute.value.path)
    return {
      navigation,
      currentRoute,
    }
  },
})
