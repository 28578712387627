export const navigation = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Menu',
    path: '/menu',
  },
  {
    name: 'About',
    path: '/about-us',
  },
]
