import { GetterTree } from 'vuex'

import { RootState } from '@/store'
import { State } from '@/store/modules/cart/state'
import { CartItem, CustomerType } from '@/types'

export type Getters = {
  getCartItems(state: State): CartItem[]
  getTotal(state: State): number
  getCustomer(state: State): CustomerType | null
}

export const getters: GetterTree<State, RootState> & Getters = {
  getCartItems: (state) => state.cartItems,
  getTotal: (state) => state.total,
  getCustomer: (state) => state.customer,
}
