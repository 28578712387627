import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.VUE_APP_WC_URL,
  auth: {
    username: process.env.VUE_APP_WC_CK,
    password: process.env.VUE_APP_WC_CS,
  },
  headers: {
    'Content-Type': 'application/graphql',
  },
})

export const instanceMain = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
})
