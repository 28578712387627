import { ActionContext, ActionTree } from 'vuex'

import { RootState } from '@/store'
import { ActionTypes } from '@/store/modules/cart/action-types'
import { MutationTypes } from '@/store/modules/cart/mutation-types'
import { State } from '@/store/modules/cart/state'
import { CartItem } from '@/types'

export interface Actions {
  [ActionTypes.ADD_TO_CART](actions: ActionContext<State, RootState>, payload: CartItem): void
  [ActionTypes.REMOVE_FROM_CART](actions: ActionContext<State, RootState>, payload: number): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionTypes.ADD_TO_CART]({ commit, state }, payload) {
    const cartItems = [...state.cartItems]
    const itemIndex = cartItems.findIndex((item) => item.product_id === payload.product_id)
    if (itemIndex === -1) cartItems.push(payload)
    else cartItems[itemIndex].quantity += payload.quantity
    commit(MutationTypes.SET_CART_ITEMS, cartItems)
    commit(MutationTypes.SET_TOTAL)
  },
  [ActionTypes.REMOVE_FROM_CART]({ commit, state }, payload) {
    const cartItems = [...state.cartItems]
    commit(
      MutationTypes.SET_CART_ITEMS,
      cartItems.filter((item) => item.product_id !== payload)
    )
    commit(MutationTypes.SET_TOTAL)
  },
}
