import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { ref: "navbarRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_navigation = _resolveComponent("the-navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_main = _resolveComponent("the-main")!
  const _component_app_alert = _resolveComponent("app-alert")!
  const _component_scroll_up = _resolveComponent("scroll-up")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_the_navigation)
    ], 512),
    _createVNode(_component_the_main, { class: "relative" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component }) => [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.alertValue.isShow)
      ? (_openBlock(), _createBlock(_component_app_alert, {
          key: 0,
          message: _ctx.alertValue.message
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    (!_ctx.targetIsVisible)
      ? (_openBlock(), _createBlock(_component_scroll_up, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_the_footer)
  ], 64))
}