import './index.css'
import 'animate.css'
import './registerServiceWorker'

import VueGoogleMaps from '@fawmi/vue-google-maps'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createHead } from '@vueuse/head'
import dayjs from 'dayjs'
import { createApp, h, provide } from 'vue'
import VueAxios from 'vue-axios'

import { apolloClient } from '@/core/apollo'
import { instance } from '@/core/instance'

import App from './App.vue'
import router from './router'
import { store } from './store'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})
  .use(router)
  .use(store)
// Axios
app.use(VueAxios, instance)
app.provide('axios', app.config.globalProperties.axios)
app.config.globalProperties.$dayjs = dayjs
// Vue Head
const head = createHead()
app.use(head)
/**
 * Google Map
 */
// Google Map
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBgztdn6VyHdBF82tZNlPtfEzeuhoNbW28',
  },
})

app.mount('#app')
