import { createLogger, createStore } from 'vuex'

import { CartStore, State as CartState, store as cart } from '@/store/modules/cart'
import { State as StoreConfigState, store as storeConfig, StoreConfigStore } from '@/store/modules/storeConfig'

export type RootState = {
  cart: CartState
  storeConfig: StoreConfigState
}
export type Store = CartStore<Pick<RootState, 'cart'>> & StoreConfigStore<Pick<RootState, 'storeConfig'>>
// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production'
const plugins = debug ? [createLogger({})] : []

export const store = createStore({
  plugins,
  modules: {
    cart,
    storeConfig,
  },
})

export function useStore(): Store {
  return store as Store
}
