import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63aa1486"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-none !mt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_check_circle_icon = _resolveComponent("check-circle-icon")!
  const _component_x_icon = _resolveComponent("x-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.isMobile ? 'container-mobile' : 'container', 'fixed z-50'])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["alert shadow-lg flex-row", [
        _ctx.alert.type === 'error' && 'alert-error',
        _ctx.alert.type === 'success' && 'alert-success',
        _ctx.alert.type === 'error' && 'alert-error',
        _ctx.alert.type === 'warning' && 'alert-warning',
      ]])
    }, [
      _createElementVNode("div", null, [
        _createVNode(_component_check_circle_icon, {
          class: "h-5 w-5",
          "aria-hidden": "true"
        }),
        _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
      ]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
          class: "btn btn-circle btn-ghost btn-xs"
        }, [
          _createVNode(_component_x_icon, {
            class: "h-5 w-5",
            "aria-hidden": "true"
          })
        ])
      ])
    ], 2)
  ], 2))
}