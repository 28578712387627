import { MutationTree } from 'vuex'

import { MutationTypes } from '@/store/modules/cart/mutation-types'
import { State } from '@/store/modules/cart/state'
import { CartItem, CustomerType } from '@/types'

export type Mutations<S = State> = {
  [MutationTypes.SET_CART_ITEMS](state: S, payload: CartItem[]): void
  [MutationTypes.SET_TOTAL](state: S): void
  [MutationTypes.SET_CUSTOMER](state: S, payload: CustomerType): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CART_ITEMS]: (state, payload) => {
    state.cartItems = payload
    localStorage.setItem('cartItems', JSON.stringify(payload))
  },
  [MutationTypes.SET_TOTAL]: (state) => {
    let total = 0
    state.cartItems.forEach((item) => (total += item.price * item.quantity))
    state.total = total
  },
  [MutationTypes.SET_CUSTOMER]: (state, payload) => {
    state.customer = payload
    localStorage.setItem('customer', JSON.stringify(payload))
  },
}
