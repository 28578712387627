
import { CheckCircleIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import { useWindowSize } from '@vueuse/core'
import { computed, defineComponent } from 'vue'

import useAlert from '@/composables/useAlert'

export default defineComponent({
  name: 'AppAlert',
  components: {
    CheckCircleIcon,
    XIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { width } = useWindowSize()
    const isMobile = computed(() => width.value <= 500)
    const { updateIsShow, alert } = useAlert()
    const close = () => updateIsShow(false)
    return {
      alert,
      close,
      isMobile,
    }
  },
})
