import { MutationTree } from 'vuex'

import { MutationTypes } from '@/store/modules/storeConfig/mutation-types'
import { State } from '@/store/modules/storeConfig/state'

export type Mutations<S = State> = {
  [MutationTypes.SET_STORE_OPEN](state: S, payload: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_STORE_OPEN]: (state, payload) => {
    state.isOpen = payload
    localStorage.setItem('is_open', JSON.stringify(payload))
  },
}
