import { reactive, watch } from 'vue'

type UseAlertType = {
  alert: {
    readonly isShow: boolean
    readonly message: string
    readonly type: 'success' | 'error' | 'warning'
  }
  updateIsShow: (isShow: boolean) => void
  updateMessage: (message: string) => void
  updateType: (type: 'success' | 'error' | 'warning') => void
  setAlert: (message: string, type: 'success' | 'error' | 'warning') => void
}

const alert = reactive({
  isShow: false,
  message: '',
  type: 'success',
})

export default function (): UseAlertType {
  const setAlert = (message: string, type: 'success' | 'error' | 'warning') => {
    alert.message = message
    alert.type = type
    alert.isShow = true
  }
  watch(alert, (newVal) => {
    if (newVal.isShow) {
      setInterval(() => (alert.isShow = false), 3000)
    }
  })
  return {
    // @ts-ignore
    alert,
    updateIsShow: (isShow) => (alert.isShow = isShow),
    updateMessage: (message) => (alert.message = message),
    updateType: (type) => (alert.type = type),
    setAlert,
  }
}
