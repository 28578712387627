import { ActionContext, ActionTree } from 'vuex'

import { instanceMain } from '@/core/instance'
import { RootState } from '@/store'
import { ActionTypes } from '@/store/modules/storeConfig/action-types'
import { MutationTypes } from '@/store/modules/storeConfig/mutation-types'
import { State } from '@/store/modules/storeConfig/state'
import { StoreConfigType } from '@/types'

export interface Actions {
  [ActionTypes.SET_STORE_OPEN](actions: ActionContext<State, RootState>): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SET_STORE_OPEN]({ commit }) {
    const { data } = await instanceMain.get<StoreConfigType>('/store-config')
    if (data) {
      commit(MutationTypes.SET_STORE_OPEN, data.data.hanoiEatery.is_open)
    }
  },
}
