
import { useElementVisibility } from '@vueuse/core'
import { defineComponent, onMounted, ref } from 'vue'

import AppAlert from '@/components/hoc/AppAlert.vue'
import ScrollUp from '@/components/ScrollUp.vue'
import useAlert from '@/composables/useAlert'
import TheMain from '@/layout/main/TheMain.vue'
import TheNavigation from '@/layout/navigation/TheNavigation.vue'
import TheFooter from '@/layout/TheFooter.vue'
import { store } from '@/store'
import { MutationTypes } from '@/store/modules/cart/mutation-types'
import { ActionTypes as StoreConfigAction } from '@/store/modules/storeConfig/action-types'

export default defineComponent({
  components: { ScrollUp, AppAlert, TheFooter, TheNavigation, TheMain },
  setup() {
    const navbarRef = ref(null)
    const targetIsVisible = useElementVisibility(navbarRef)

    const onGetCartItems = () => {
      const cartItemsLocal = localStorage.getItem('cartItems')
      const customer = localStorage.getItem('customer')
      if (cartItemsLocal) {
        store.commit(MutationTypes.SET_CART_ITEMS, JSON.parse(cartItemsLocal))
        store.commit(MutationTypes.SET_TOTAL)
      }
      if (customer) {
        store.commit(MutationTypes.SET_CUSTOMER, JSON.parse(customer))
      }
    }
    onMounted(onGetCartItems)
    onMounted(() => {
      store.dispatch(StoreConfigAction.SET_STORE_OPEN)
    })

    const { alert: alertValue } = useAlert()
    return {
      alertValue,
      navbarRef,
      targetIsVisible,
    }
  },
})
