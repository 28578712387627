import { GetterTree } from 'vuex'

import { RootState } from '@/store'
import { State } from '@/store/modules/storeConfig/state'

export type Getters = {
  getStoreOpen(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
  getStoreOpen: (state) => state.isOpen,
}
